<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        :records="transactions" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :current-page-number.sync="currentPage"
        :show-export-button="false"
        :show-search="false"
      >
        <template #action_button>
          <b-button
            variant="primary"
            :disabled="loading"
            @click="onGoToPledge"
          >
            <span class="text-nowrap">Pledge Offering</span>
          </b-button>
        </template>

        <template #cell(offering_type)="data">
          <p class="mb-2">
            {{ data.item.offering_type.title }}
          </p>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(meta)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(getValueFromSource(data.item, 'meta.church_approval'))}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(getValueFromSource(data.item, 'meta.church_approval')) }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="cancelRecurringOffering(data.item._id)"
            >
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">Cancel</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BInputGroup, BInputGroupAppend, BFormInput, BFormDatepicker
} from 'bootstrap-vue'

import { get, debounce } from "lodash"

// eslint-disable-next-line import/no-extraneous-dependencies
import printJS from 'print-js'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileDownload from "js-file-download";
import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { base64toBlob } from "@core/utils/utils";

const watchHandler = {
  handler(){
    this.debouncedFetchData()
  }, 
  immediate: false
}

export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BFormInput,
    BInputGroup, 
    BFormDatepicker,
    BInputGroupAppend, 

    vSelect,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      loading: false,

      showFilter: false,
      showListTable: false,
      
      searchFilter: null,
      dateFrom: null,
      dateTo: null,
      frequency_title: {
        every_day: 'Every Day',
        every_week: 'Every Week',
        every_2_weeks: 'Every 2 Weeks',
        every_month: 'Every Month',
      },

      transactions: [],

      tableColumns: [
        { key: 'amount', sortable: false, formatter: val => `GH₵ ${this.formatMoney(val)}` },
        { key: 'frequency', sortable: false, formatter: val => this.frequency_title[val] },
        { key: 'church.church_level', label: 'Church Level', sortable: false, formatter: val => (val ? this.formatChurchLevel(val) : 'N/A') },
        { key: 'offering_type.title', sortable: false, label: 'Offering' },
        { key: 'cover_fees', sortable: false, formatter: val => (val ? 'Yes' : 'No') },
        { key: 'status', sortable: false, label: 'Status' },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
        { key: 'actions', sortable: false, label: 'Actions' },
      ],
      debouncedFetchData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
  },
  created(){
    this.debouncedFetchData = debounce(this.fetchAllData, 500);

    const { 
      page = 1, 
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.perPage = +limit;

    this.fetchAllData();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().memberService.fetchRecurringOfferings(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.transactions = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportTransactions(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          search: this.searchFilter,
          from: this.dateFrom,
          to: this.dateTo
        }

        const response = await this.useJwt().memberService.exportTransactions(query);
        FileDownload(response.data, "my-transactions.csv");
      } catch (error) {
        // const error_message = get(error, "response.statusText") || error.message;
        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'There is no data to export'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async onDownloadReceipt(transaction_id) {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Print?',
          text: "Do you want to print receipt for this transaction?",
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const response = await this.useJwt().memberService.downloadOfferingReceipt(transaction_id);
        const { pdf } = response.data.data;

        this.printPDF(pdf)
        // FileDownload(response.data, "my-transactions.csv");
      } catch (error) {
        // const error_message = get(error, "response.statusText") || error.message;
        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Unable to print receipt. Try again later.'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async cancelRecurringOffering(offering_pledge_id){
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Cancel this pledge?',
          text: "You are about to cancel this pledge for recurring offering. Continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, cancel!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value){
          return;
        }

        await this.useJwt().memberService.cancelRecurringOffering(offering_pledge_id);
        this.fetchAllData();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            icon: 'EditIcon',
            variant: 'success',
            text: 'Offering pledge cancelled successful'
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onGoToPledge() {
      this.$router.replace({ name: 'member-give', query: { gift_type: 'recurring' } })
    },
    printPDF(content) {
      const url = URL.createObjectURL(base64toBlob(content));

      printJS({
        printable: url,
        type: "pdf"
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>
